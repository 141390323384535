import type { ITimeUnit, TArgsValue, TFormatList } from '~/components/SharedTimer/SharedTimer.types';
import { getDateTitle, calculatedTime } from '~/utils/timer.utils';

const { daysCalculated, minutesCalculated, secondsCalculated } = calculatedTime();
export const formatList: TFormatList = {
  day: {
    days: (val) => daysCalculated(val),
    hours: (val) => (val % 86400) / 3600,
    minutes: (val) => minutesCalculated(val),
    seconds: (val) => secondsCalculated(val),
  },
  time: {
    days: (val) => daysCalculated(val),
    hours: (val) => val / 3600,
    minutes: (val) => minutesCalculated(val),
    seconds: (val) => secondsCalculated(val),
  },
};

export const createTimeArray = ({ days, hours, minutes, seconds }: TArgsValue): ITimeUnit[] => [
  {
    description: {
      colon: ':',
      mini: 'Д',
      text: getDateTitle,
    },
    key: days,
    type: 'days',
  },
  {
    description: {
      colon: ':',
      mini: ':',
      text: getDateTitle,
    },
    key: hours,
    type: 'hours',
  },
  {
    description: {
      colon: ':',
      mini: ':',
      text: getDateTitle,
    },
    key: minutes,
    type: 'minutes',
  },
  {
    description: {
      colon: '',
      mini: '',
      text: getDateTitle,
    },
    key: seconds,
    type: 'seconds',
  },
];

export const calculateTimeColumns = (milliseconds: number) => {
  const minutes = milliseconds / 1000 / 60;
  let columns = 2;

  if (minutes > 60) {
    // with hours
    columns = 3;
  } else if (minutes / 60 > 24) {
    // with days
    columns = 4;
  } else if (minutes / 60 / 24 > 7) {
    // with weeks
    columns = 5;
  }

  return columns;
};
