import type { ISharedButtonNewColorPreset, ISharedButtonNewFormPreset } from '~/types/SharedButtonNew.types';
import {
  defaultButtonColorPreset,
  defaultMiddleButtonFormPreset,
} from '~/components/SharedButtonNew/SharedButtonNew.data';

export const returnToCaseButtonFormPreset: ISharedButtonNewFormPreset = {
  ...defaultMiddleButtonFormPreset,
  height: '40px',
  margin: 'var(--gap-2xs) 0',
  minWidth: '100%',
  radius: 'var(--border-radius-3xs)',
  width: '100%',
};

export const returnToCaseButtonColorPreset: ISharedButtonNewColorPreset = {
  ...defaultButtonColorPreset,
  color: 'var(--main-color-400)',
  colorHovered: 'var(--main-color-300)',
};
