<template>
  <div :class="dropSkinsRootClasses">
    <div :class="dropSkinsActionClasses">
      <div class="actions__block actions__block_main">
        <SharedButton
          :font-size="fontSize || buttonsFontSize"
          radius="0"
          full-width
          full-height
          disable-minimal-width
          :text-props="props.mainButton.textProps || {}"
          v-bind="props.mainButton.buttonProps || {}"
          @click="props.mainButton.onClick"
        >
          <slot name="mainButtonText">
            {{ mainLabel || '' }}
          </slot>
          <template v-if="priceTimeAvailable" #append>
            <div class="block__main_timer">
              <SharedPrice
                size="smallest"
                theme="light"
                :currency="offer?.currency ?? 'USD'"
                :color="priceColor"
                :price="String(props?.offer?.price) || ''"
                :price-text-props="{
                  weight: FontWeights.BOLD,
                  size: FontSizes.MEDIUM,
                }"
              />
              <SharedTimer
                size="smallest"
                :theme="theme"
                format="time"
                :server-date="serverDate ? timestampToDate(+serverDate) : 0"
                description-date="colon"
                :date="timestampToDate(+offer!.countdown, false)"
                :text-props="{
                  size: isSmall ? FontSizes.MEDIUM : FontSizes.LARGE,
                  weight: FontWeights.BLACK,
                }"
                @on-expire="$emit('onExpire')"
              />
            </div>
          </template>
        </SharedButton>
      </div>
      <div v-if="actionButtons.length" class="actions__block actions__block_secondary">
        <SharedButton
          v-for="btn in buttonsToShow"
          :key="btn.label"
          :font-size="fontSize || buttonsFontSize"
          radius="0"
          full-height
          full-width
          :width="GlobalUtils.Converting.toPercents(100 / actionButtons.length)"
          disable-minimal-width
          :text-props="btn.textProps"
          v-bind="btn.buttonProps"
          @click="btn.onClick?.(inventoryId)"
        >
          {{ btn.label }}
        </SharedButton>
      </div>
    </div>
    <SharedButtonNew
      v-if="isMobile && actionButtons.length > 2"
      :form="returnToCaseButtonFormPreset"
      :color="returnToCaseButtonColorPreset"
      @click="actionButtons[1].onClick?.(inventoryId)"
    >
      {{ actionButtons[1].label }}
    </SharedButtonNew>
    <div class="dropSkins__info">
      <slot name="bottomInfo">
        <SharedText
          v-if="props.bottomInfo"
          as="div"
          class="dropSkins__info_text"
          :weight="FontWeights.MEDIUM"
          :size="FontSizes.LARGE"
          align="center"
          v-bind="props.bottomInfo.textProps || {}"
        >
          {{ props.bottomInfo.text || '' }}
        </SharedText>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, toRefs } from 'vue';
import type { ITimerEmits } from '../SharedTimer/SharedTimer.types';
import SharedText from '~/components/SharedText/SharedText.vue';
import SharedButton from '~/components/SharedButton/SharedButton.vue';
import { FontSizes, FontWeights } from '~/types/SharedFont.types';
import type { IDropSkinsProps } from '~/components/DropSkinsButtons/DropSkinsButtons.types';
import { Colors } from '~/constants/colors.constants';
import { Breakpoints } from '~/constants/media.constants';
import {
  returnToCaseButtonColorPreset,
  returnToCaseButtonFormPreset,
} from '~/components/DropSkinsButtons/DropSkinsButtons.data';

const { t } = useI18n();

const COMPONENT_NAME = 'dropSkins';

const props = withDefaults(defineProps<IDropSkinsProps>(), {
  actionButtons: () => [],
  actionGap: '50px',
  mainButton: () => ({}),
  priceTimeAvailable: false,
  serverDate: 0,
  size: 'large',
  theme: 'red',
});

defineEmits<ITimerEmits>();
const mainLabel = computed(() => props.mainLabel ?? t('sell'));

const { size, actionButtons, offer, priceTimeAvailable, serverDate, inventoryId } = toRefs(props);
const isSmall = computed(() => size.value === 'small');

const dropSkinsRootClasses = computed(() => ({
  [COMPONENT_NAME]: true,
  [`${COMPONENT_NAME}--${props.size}`]: size.value,
}));

const dropSkinsActionClasses = computed(() => ({
  [`${COMPONENT_NAME}__actions`]: true,
  [`${COMPONENT_NAME}__actions--many`]: actionButtons.value.length > 2,
}));

const priceColor = computed(() => {
  if (props.theme === 'purple') return Colors.BK_INTEGRATION.TOP_PRICE_PURPLE;
  return Colors.ADDITIONAL.RED;
});

const isMobileSize = () => useMedia('down')[Breakpoints.md];

const buttonsFontSize = computed(() => {
  if (isMobileSize()) {
    return FontSizes.MEDIUM;
  } else {
    return isSmall.value ? FontSizes.MEDIUM : FontSizes.LARGE;
  }
});

const viewport = useViewport();

const isMobile = computed(() => {
  return viewport.isLessThan(Breakpoints.md);
});

// Такая абстракция нужна, чтобы не сломать компонент
// при 2 элементах в actionButtons[] && при полном экране
const buttonsToShow = computed(() => {
  if (isMobile.value && actionButtons.value.length > 2) {
    return [actionButtons.value[0], actionButtons.value[2]];
  }
  return actionButtons.value;
});
</script>

<style src="./DropSkinsButtons.scss" scoped lang="scss"></style>
<style lang="scss">
.dropSkins__actions {
  grid-template-rows: v-bind('props.actionGap') v-bind('props.actionGap');
}
</style>
