<template>
  <ClientOnly>
    <div :class="classes">
      <SharedText
        v-for="elTime in timeArray"
        :key="elTime.key.value"
        :weight="FontWeights.BLACK"
        as="p"
        class="timer__cell"
        v-bind="props.textProps || {}"
      >
        {{ elTime.key.value }}
        <SharedText class="timer__cell_element" as="span" display="block">{{ getDescriptionTime(elTime) }}</SharedText>
      </SharedText>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { ref, computed, onUnmounted } from 'vue';
import { calculateTimeColumns, createTimeArray, formatList } from './SharedTimer.data';
import type { IPropsSharedTimer, ITimerEmits, ITimeUnit } from './SharedTimer.types';
import { FontWeights } from '~/types/SharedFont.types';

const { t } = useI18n();

const emits = defineEmits<ITimerEmits>();

const props = withDefaults(defineProps<IPropsSharedTimer>(), {
  descriptionDate: 'text',
  disablePadding: false,
  format: 'day',
  serverDate: 0,
  size: 'normal',
  theme: 'dark',
});

const { serverDate, date: dateRef, format, descriptionDate } = toRefs(props);
const finishDate = computed(() => new Date(dateRef.value));
const nowDate = new Date();
const initialTime = +finishDate.value - +nowDate > 0 ? Math.floor((+finishDate.value - +nowDate) / 1000) : 0;
const time = ref(initialTime);

const getCorrectValue = (expression: number): string => {
  const beforeFloorValue = String(Math.floor(expression));
  return beforeFloorValue.padStart(2, '0');
};

const days = computed<string>(() => getCorrectValue(formatList[format.value].days(time.value)));
const hours = computed<string>(() => getCorrectValue(formatList[format.value].hours(time.value)));
const minutes = computed<string>(() => getCorrectValue(formatList[format.value].minutes(time.value)));
const seconds = computed<string>(() => getCorrectValue(formatList[format.value].seconds(time.value)));

// Preparing the date for work
const transformTimeArrayByMode = (timeArray: ITimeUnit[]): ITimeUnit[] => {
  if (format.value === 'day') return timeArray;

  const columnsToSlice = typeof dateRef.value === 'number' ? timeArray.length - calculateTimeColumns(dateRef.value) : 1;
  return timeArray.slice(columnsToSlice);
};

const timeArray = computed<ITimeUnit[]>(() => {
  const result = transformTimeArrayByMode(createTimeArray({ days, hours, minutes, seconds }));
  if (props.hideSeconds) {
    result.pop();
    result[result.length - 1].description = {
      colon: '',
      mini: '',
      text: getDateTitle,
    };
  }
  return result;
});

const getDescriptionTime = (item: ITimeUnit): string | undefined => {
  const description = item.description[descriptionDate.value];
  return typeof description === 'function' ? description(+item.key.value, item.type, t) : description;
};

// Interval
let interval: NodeJS.Timeout;

let tick = 0;

const setTimeValue = () => {
  if (serverDate.value) {
    // добавил серверное время, чтобы отвязаться от часовых поясов
    time.value = (Date.parse(dateRef.value.toString()) - Date.parse(serverDate.value.toString())) / 1000 - tick;
    tick += 1;
  } else {
    time.value = Math.floor((+finishDate.value - +new Date()) / 1000);
  }
  if (time.value < 0 || isNaN(time.value)) {
    time.value = 0;
    emits('onExpire');
    clearInterval(interval);
  }
};

onMounted(() => {
  setTimeValue();
  interval = setInterval(setTimeValue, 1000);
});

onUnmounted(() => clearInterval(interval));

const classes = computed(() => ({
  'timer': true,
  'timer--light': props.theme === 'light',
  'timer--mini': props.descriptionDate === 'mini',
  'timer--purple': props.theme === 'purple',
  'timer--red': props.theme === 'red',
  'timer--small': props.size === 'small',
  'timer--smallest': props.size === 'smallest',
}));
</script>

<style src="./SharedTimer.scss" lang="scss" scoped></style>
